<template>
  <div>
    <div class="modal fade" tabindex="-1" role="dialog" ref="paymentModal">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content bg-white">
          <div class="modal-header">
            <h5 class="modal-title">Make a Payment</h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal" data-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <div class="row mb-4">
              <div class="col">
                <table class="table mb-0 p-2">
                  <tbody>
                    <tr>
                      <th style="border-top: none">Supplier:</th>
                      <td style="border-top: none">
                        {{ selectedBill ? selectedBill.supplier.name : "" }}
                      </td>
                    </tr>
                    <tr>
                      <th>Order ID:</th>
                      <td>{{ selectedBill ? selectedBill.order.id : "" }}</td>
                    </tr>
                    <tr>
                      <th>Bill ID:</th>
                      <td>{{ selectedBill ? selectedBill.id : "" }}</td>
                    </tr>
                    <tr>
                      <th>Outstanding:</th>
                      <td>
                        £{{
                          selectedBill
                            ? selectedBill.amountOutstanding.toFixed(2)
                            : ""
                        }}
                      </td>
                    </tr>
                    <tr>
                      <th>Payment Amount:</th>
                      <td>
                        <currency-input
                          v-model="billPaymentAmount"
                          currency="GBP"
                          locale="gb"
                          :min="0"
                          :max="
                            selectedBill ? selectedBill.amountOutstanding : 0
                          "
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>

                <table class="table mb-0 p-2">
                  <tbody>
                    <tr>
                      <th class="border-0">Payment Method:</th>
                      <td class="border-0">
                        <div class="custom-control custom-radio">
                          <input
                            type="radio"
                            name="paymentMethod"
                            id="card"
                            value="card"
                            v-model="paymentMethod"
                            class="custom-control-input me-1"
                          />
                          <label class="custom-control-label" for="card"
                            >Card Payment</label
                          >
                        </div>
                        <div class="custom-control custom-radio">
                          <span v-tooltip.bottom="'External Payment'">
                            <input
                              type="radio"
                              name="paymentMethod"
                              id="external"
                              value="external"
                              v-model="paymentMethod"
                              class="custom-control-input me-1"
                            />
                            <label class="custom-control-label" for="external"
                              >Cash / Bank Transfer</label
                            ></span
                          >
                        </div>
                      </td>
                    </tr>

                    <tr
                      :class="
                        !cardPaymentEnabled || paymentMethod !== 'card'
                          ? 'd-none'
                          : ''
                      "
                    >
                      <td colspan="2">
                        <label for="payment-card-element"
                          >Credit / Debit Card Details</label
                        >
                        <div id="payment-card-element"></div>
                        <div id="card-errors" role="alert"></div>
                      </td>
                    </tr>

                    <tr
                      :class="
                        paymentMethod !== 'card' || cardPaymentEnabled
                          ? 'd-none'
                          : ''
                      "
                    >
                      <td colspan="2">
                        <p class="text-center">
                          <i class="fa fa-exclamation-circle me-2"></i> Card
                          payment is not available for this order.
                        </p>
                        <div>
                          <p class="alert alert-info text-center">
                            Supplier has not integrated payments with
                            Swandoola.<br /><br />
                            Please check back in the near future.
                          </p>
                        </div>
                        <p class="text-center">
                          In the meantime, you can make and record an external
                          payment.
                        </p>
                      </td>
                    </tr>

                    <tr :class="paymentMethod !== 'external' ? 'd-none' : ''">
                      <td colspan="2">
                        <label for="external-payment-ref me-1"
                          >Payment Reference</label
                        >
                        <input
                          type="text"
                          name="external-payment-ref"
                          id="external-payment-ref"
                          v-model="externalPaymentRef"
                          class="form-control"
                          :required="paymentMethod === 'external'"
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-outline-secondary btn-sm me-auto"
              data-bs-dismiss="modal" data-dismiss="modal"
              aria-label="Close"
            >
              Cancel
            </button>
            <button
              type="button"
              class="btn btn-primary btn-sm"
              :disabled="!paymentMethod"
              @click="processPayment"
            >
              Make Payment
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="modal fade" tabindex="-1" role="dialog" ref="bulkPaymentModal">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content bg-white">
          <div class="modal-header">
            <h5 class="modal-title">Make a Bulk Payment</h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal" data-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <div class="row mb-4">
              <div class="col">
                <table class="table mb-0 p-2 bulk-payment-bill-container">
                  <tbody>
                    <!-- <tr v-for="bill in selectedBills" v-bind:key="bill.id">
                      <th style="border-top: none">
                        {{ bill ? bill.supplier.name : "" }} (#{{ bill ? bill.id : "" }})
                      </th>
                      <td style="border-top: none">
                        £{{ bill ? bill.amountOutstanding.toFixed(2) : "" }}
                      </td>
                    </tr>

                    <tr>
                      <td colspan="2"><br /></td>
                    </tr> -->

                    <tr>
                      <th style="border-top: none">
                        Total Amount:
                      </th>
                      <td style="border-top: none">
                        £{{ totalSelectedAmount }}
                      </td>
                    </tr>
                    <tr>
                      <th style="border-top: none">
                        Payment Method:
                      </th>
                      <td style="border-top: none">
                        <div class="custom-control custom-radio">
                          <input
                            type="radio"
                            name="bulkPaymentMethod"
                            id="bulkCard"
                            value="card"
                            v-model="bulkPaymentMethod"
                            class="custom-control-input me-1"
                          />
                          <label class="custom-control-label" for="bulkCard"
                            >Card Payment</label
                          >
                        </div>
                        <div class="custom-control custom-radio">
                          <span v-tooltip.bottom="'External Payment'">
                            <input
                              type="radio"
                              name="bulkPaymentMethod"
                              id="bulkExternal"
                              value="external"
                              v-model="bulkPaymentMethod"
                              class="custom-control-input me-1"
                            />
                            <label class="custom-control-label" for="bulkExternal"
                              >Cash / Bank Transfer</label
                            ></span
                          >
                        </div>
                      </td>
                    </tr>

                    <tr
                      :class="
                        !bulkCardPaymentEnabled || bulkPaymentMethod !== 'card'
                          ? 'd-none'
                          : ''
                      "
                    >
                      <td colspan="2" style="border-top: none">
                        <label for="bulk-payment-card-element"
                          >Credit / Debit Card Details</label
                        >
                        <div id="bulk-payment-card-element"></div>
                        <div id="bulk-card-errors" role="alert"></div>
                      </td>
                    </tr>

                    <tr
                      :class="
                        bulkPaymentMethod !== 'card' || bulkCardPaymentEnabled
                          ? 'd-none'
                          : ''
                      "
                    >
                      <td colspan="2">
                        <p class="text-center">
                          <i class="fa fa-exclamation-circle me-2"></i> Card
                          payment is not available for this order.
                        </p>
                        <div>
                          <p class="alert alert-info text-center">
                            Supplier has not integrated payments with
                            Swandoola.<br /><br />
                            Please check back in the near future.
                          </p>
                        </div>
                        <p class="text-center">
                          In the meantime, you can make and record an external
                          payment.
                        </p>
                      </td>
                    </tr>

                    <tr :class="bulkPaymentMethod !== 'external' ? 'd-none' : ''">
                      <td colspan="2" style="border-top: none">
                        <label for="external-payment-ref"
                          >Payment Reference</label
                        >
                        <input
                          type="text"
                          name="bulk-external-payment-ref"
                          id="bulk-external-payment-ref"
                          v-model="bulkExternalPaymentRef"
                          class="form-control ms-1"
                          :required="bulkPaymentMethod === 'external'"
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-outline-secondary btn-sm me-auto"
              data-bs-dismiss="modal" data-dismiss="modal"
            >
              Cancel
            </button>
            <button
              type="button"
              class="btn btn-primary btn-sm"
              :disabled="!bulkPaymentMethod"
              @click="processBulkPayment"
            >
              Make Bulk Payment (£{{ totalSelectedAmount }})
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="orders-tab">
      <div class="row mb-0">
        <div class="col-auto my-auto d-flex align-items-center w-100">
          <h5 class="mb-0 client-tab-heading">Supplier Payments</h5>

          <div class="col text-end">
            <button
              @click="toOrders()"
              class="btn btn-outline-secondary btn-sm"
            >
              <i class="far fa-clipboard-list me-1"></i>
              Orders
            </button>
          </div>
        </div>
      </div>

      <hr class="my-3" />

      <div class="row mb-2">
        <div class="col">
          <ul class="nav nav-tabs">
            <li @click="switchToTab(0)" class="nav-item me-2">
              <a
                href="#outstanding"
                :class="viewTab == 0 ? 'nav-link active' : 'nav-link'"
                >Outstanding Payments</a
              >
            </li>
            <li @click="switchToTab(1)" class="nav-item me-2">
              <a
                href="#completed"
                :class="viewTab == 1 ? 'nav-link active' : 'nav-link'"
                >Completed Payments</a
              >
            </li>
          </ul>
        </div>
      </div>

      <div v-if="viewTab == 0" class="row mb-2">
        <div class="col">
          <div class="card border-0 shadow-sm">
            <div class="card-header pb-1">
              <div class="row d-flex align-items-center">
                <div class="col-auto my-auto">
                  <button
                    v-for="supplier in distinctSuppliers" v-bind:key="supplier.id"
                    @click="filterBySupplier(supplier)"
                    :ref="'filter-supplier-' + supplier.replace(/\s+/g, '')"
                    class="btn btn-outline-info btn-sm mx-1"
                    :class="currentSupplierFilter === supplier ? 'active' : ''"
                  >
                    {{ supplier }}
                  </button>
                  <button
                    class="btn btn-outline-secondary btn-sm mx-1"
                    ref="clearSupplierFiltersButton"
                    @click="showAllSuppliers"
                  >
                    <i class="far fa-times me-2"></i>Clear Filters
                  </button>
                </div>
              </div>
            </div>
            <div class="card-body">
              <div class="row my-4" v-if="!loadingBills && currentOutstandingBills.length > 0">
                <div class="col">
                  <table class="table">
                    <thead>
                      <tr>
                        <th scope="col">
                          <input
                            type="checkbox"
                            @change="checkAll()"
                            v-model="checkAllSelected"
                          />
                        </th>
                        <th scope="col">#</th>
                        <th scope="col">Supplier</th>
                        <th scope="col">Order ID</th>
                        <th scope="col">Total Amount</th>
                        <th scope="col">Amount Outstanding</th>
                        <th scope="col">Amount Paid</th>
                        <th scope="col">Status</th>
                        <th scope="col">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="bill in currentOutstandingBills" v-bind:key="bill.id">
                        <td>
                          <input
                            type="checkbox"
                            v-bind:id="bill.id"
                            v-bind:value="bill.id"
                            v-model="checkedBills"
                          />
                        </td>
                        <td>{{ bill.id }}</td>
                        <td>{{ bill.supplier.name }}</td>
                        <td>{{ bill.order.id }}</td>
                        <td>£{{ bill.total.toFixed(2) }}</td>
                        <td>£{{ bill.amountOutstanding.toFixed(2) }}</td>
                        <td>£{{ bill.amountPaid.toFixed(2) }}</td>
                        <td>
                          <span
                            class="badge p-1"
                            :class="
                              bill.payment_status === 'paid'
                                ? 'bg-primary'
                                : 'bg-danger'
                            "
                          >
                            {{
                              bill.payment_status === "paid"
                                ? "Paid"
                                : "Amount Outstanding"
                            }}
                          </span>
                        </td>
                        <td>
                          <button
                            class="btn btn-outline-primary btn-sm"
                            @click="showPaymentModal(bill)"
                          >
                            Make Payment
                          </button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <div v-if="this.checkedBills.length > 1">
                    <button
                      class="btn btn-outline-primary btn-sm"
                      @click="showBulkPaymentModal"
                    >
                      Make Bulk Payment (£{{ totalSelectedAmount }})
                    </button>
                  </div>
                </div>
              </div>
              <div
                class="row my-4 text-center"
                v-if="!loadingBills && currentOutstandingBills.length == 0"
              >
                <div class="col">
                  <h5>There are no outstanding payments.</h5>
                </div>
              </div>

              <loading v-if="loadingBills" class="my-4"></loading>
            </div>
          </div>
        </div>
        </div>
      </div>

      <div v-if="viewTab == 1" class="row mb-2">
        <div class="col">
          <div class="card border-0 shadow-sm">
              <div class="card-header pb-1">
                <div class="row d-flex align-items-center">
                  <div class="col-auto my-auto">
                    <button
                      v-for="supplier in distinctSuppliers" v-bind:key="supplier.id"
                      @click="filterBySupplier(supplier)"
                      :ref="'filter-supplier-' + supplier.replace(/\s+/g, '')"
                      class="btn btn-outline-info btn-sm mx-1"
                      :class="currentSupplierFilter === supplier ? 'active' : ''"
                    >
                      {{ supplier }}
                    </button>
                    <button
                      class="btn btn-outline-secondary btn-sm mx-1"
                      ref="clearSupplierFiltersButton"
                      @click="showAllSuppliers"
                    >
                      <i class="far fa-times me-2"></i>Clear Filters
                    </button>
                  </div>
                </div>
              </div>
              <div class="card-body">
                <div class="row my-4" v-if="!loadingPayments && currentCompletedPayments.length > 0">
                  <div class="col">
                    <table class="table">
                      <thead>
                        <tr>
                          <th scope="col">#</th>
                          <th scope="col">Supplier</th>
                          <th scope="col">Order ID</th>
                          <th scope="col">Total Paid</th>
                          <th scope="col">Payment Method</th>
                          <th scope="col">Status</th>
                          <th scope="col">Date</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="p in currentCompletedPayments" v-bind:key="p.id"
                          @click="openPayment(p)"
                          class="cursor-pointer"
                        >
                          <th scope="row">{{ p.id }}</th>
                          <td>{{ p.supplier.name }}</td>
                          <td>{{ p.order_id }}</td>
                          <td>£{{ p.amount.toFixed(2) }}</td>
                          <td>{{ p.payment_method | formatUppercaseWords }}</td>
                          <td>
                            <span class="badge badge-primary p-1">Paid</span>
                          </td>
                          <td>{{ p.updated_at | formatDate }}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div
                  class="row my-4 text-center"
                  v-if="!loadingPayments && currentCompletedPayments.length == 0"
                >
                  <div class="col">
                    <h5>There are no completed payments.</h5>
                  </div>
                </div>

                <loading v-if="loadingPayments" class="my-4"></loading>
              </div>
            </div>
          </div>
        </div>
      </div>
  </div>
</template>

<script>

import { CurrencyInput } from "vue-currency-input";
import Loading from "@/views/components/loader/Loading";

// @todo Paginate?

export default {
  data() {
    return {
      stripe_key: process.env.VUE_APP_STRIPE_KEY,
      completedPayments: [],
      currentCompletedPayments: [],
      outstandingBills: [],
      currentOutstandingBills: [],
      checkedBills: [],
      currentSupplierFilter: null,
      checkAllSelected: false,
      selectedBill: null,
      distinctSuppliers: [],
      paymentMethod: null,
      billPaymentAmount: null,
      bulkPaymentMethod: null,
      externalPaymentRef: null,
      bulkExternalPaymentRef: null,
      cardElement: null,
      bulkCardElement: null,
      intent: null,
      bulkIntents: [],
      stripe: this.stripe_key ? Stripe(this.stripe_key) : null,
      cardPaymentEnabled: true,
      bulkCardPaymentEnabled: true,
      loadingBills: true,
      loadingPayments: true,
      viewTab: 0
    };
  },
  props: [],
  methods: {
    checkAll() {
      if (this.checkAllSelected) {
        for (let i = 0; i < this.currentOutstandingBills.length; i++) {
          this.checkedBills.push(this.currentOutstandingBills[i].id);
        }
      } else {
        this.checkedBills = [];
      }
    },
    showPaymentModal(bill) {
      this.selectedBill = bill;

      //card payment is only enabled if supplier has active stripe integration
      if (
        !this.selectedBill.supplier.stripe_integration ||
        this.selectedBill.supplier.stripe_integration.status !== "active"
      ) {
        this.cardPaymentEnabled = false;
      }

      let element = this.$refs.paymentModal;
      $(element).modal("show");
      this.cardElement = this.createCardElement("#payment-card-element");
    },
    showBulkPaymentModal() {
      //card payment is only enabled if All of order's suppliers have active stripe integration

      var supplierValid = true;
      Object.keys(this.selectedBills).forEach((key) => {
        let bill = this.selectedBills[key];
        let supplier = bill.supplier;
        if (supplier) {
          if (
            !supplier.stripe_integration ||
            supplier.stripe_integration.status !== "active"
          ) {
            this.bulkCardPaymentEnabled = false;
          }
        }
      });

      let element = this.$refs.bulkPaymentModal;
      $(element).modal("show");
      this.bulkCardElement = this.createCardElement(
        "#bulk-payment-card-element"
      );
    },
    processPayment() {
      let self = this;
      this.busy = true;

      if (this.paymentMethod === "card") {
        this.$axios
          .post(process.env.VUE_APP_API_URL + "/supplier-payments/pay-bill", {
            bill_id: this.selectedBill.id,
            amount: this.billPaymentAmount,
          })
          .then(({ data }) => {
            this.intent = data.intent;

            this.stripe
              .handleCardPayment(this.intent, this.cardElement)
              .then(function (result) {
                if (result.error) {
                  self.$forceUpdate();
                  this.$EventBus.$emit("alert", result.error);
                  const vm = this;
                  setTimeout(() => {
                    vm.$router.push("/supplier-payments");
                  }, 3500);
                } else if (
                  result.paymentIntent &&
                  result.paymentIntent.status === "succeeded"
                ) {
                  self.$forceUpdate();
                  self.recordCardPayment(
                    result.paymentIntent,
                    self.selectedBill.id
                  );
                  this.$EventBus.$emit("alert", {
                    message:
                      "Thank you, your payment has been processed successfully, confirmation has been sent via email",
                  });
                  const vm = this;
                  setTimeout(() => {
                    vm.$router.push("/supplier-payments");
                  }, 3500);
                }
              });
          });
      } else if (this.paymentMethod === "external") {
        this.recordExternalPayment().then(() => {
          this.$EventBus.$emit("alert", {
            message: "Thank you, your payment has been recorded successfully.",
          });
          const vm = this;
          setTimeout(() => {
            vm.$router.push("/supplier-payments");
          }, 3500);
        });
      }
    },
    processBulkPayment() {
      let self = this;
      this.busy = true;

      if (this.bulkPaymentMethod === "card") {
        this.$axios
          .post(
            process.env.VUE_APP_API_URL + "/supplier-payments/pay-bulk-bills",
            {
              bills: this.selectedBills,
            }
          )
          .then(({ data }) => {
            this.bulkIntents = data.intents;
            let self = this;

            this.bulkIntents
              .reduce(function (p, item) {
                return p.then(function () {
                  return self.stripeHandleCardPaymentPromise(item);
                });
              }, Promise.resolve())
              .then(function (results) {
                self.bulkIntents = [];
                this.$EventBus.$emit("alert", {
                  message:
                    "Thank you, your payment has been recorded successfully.",
                });
                const vm = this;
                setTimeout(() => {
                  vm.$router.push("/supplier-payments");
                }, 3500);
              });
          });
      } else if (this.bulkPaymentMethod === "external") {
        this.recordBulkExternalPayment().then(() => {
          this.$EventBus.$emit("alert", {
            message: "Thank you, your payment has been recorded successfully.",
          });
          const vm = this;
          setTimeout(() => {
            vm.$router.push("/supplier-payments");
          }, 3500);
        });
      }
    },
    stripeHandleCardPaymentPromise(intent) {
      let self = this;
      return this.stripe
        .handleCardPayment(
          intent["intent"]["client_secret"],
          this.bulkCardElement
        )
        .then(
          function (result) {
            if (result.error) {
              console.log(result.error);
              self.$forceUpdate();
              this.$EventBus.$emit("alert", result.error);
              const vm = this;
              setTimeout(() => {
                vm.$router.push("/supplier-payments");
              }, 3500);
            } else if (
              result.paymentIntent &&
              result.paymentIntent.status === "succeeded"
            ) {
              self.$forceUpdate();
              self.recordCardPayment(result.paymentIntent, intent["bill_id"]);
            }
          },
          function (err) {
            console.log("err:", err); // Handle your error here.
          }
        );
    },
    recordCardPayment(intent, bill_id) {
      this.$axios.post(
        process.env.VUE_APP_API_URL + "/supplier-payments/record-card-payment",
        {
          bill_id: bill_id,
          intent: intent,
        }
      );
    },
    recordExternalPayment() {
      return this.$axios.post(
        process.env.VUE_APP_API_URL +
          "/supplier-payments/record-external-payment",
        {
          bill_id: this.selectedBill.id,
          external_ref: this.externalPaymentRef,
          amount: this.billPaymentAmount,
        }
      );
    },
    recordBulkExternalPayment() {
      return this.$axios.post(
        process.env.VUE_APP_API_URL +
          "/supplier-payments/record-bulk-external-payment",
        {
          bills: this.selectedBills,
        }
      );
    },
    openPayment(p) {
      if (p.status == "not_paid") {
        this.$router.push("/supplier-payments/" + p.id);
      } else {
        this.$EventBus.$emit("alert", {
          message:
            "This supplier payment has already been paid, create a new one to pay the supplier again.",
        });
      }
    },
    fetchOutstandingBills() {
      this.loadingBills = true;

      this.$axios
        .post(
          process.env.VUE_APP_API_URL + "/supplier-payments/fetch-bills",
          {}
        )
        .then((response) => {
          this.outstandingBills = response.data;
          this.currentOutstandingBills = this.outstandingBills;
          this.gatherSuppliers();
          this.loadingBills = false;
        });
    },
    fetchCompletedPayments() {
      this.loadingPayments = true;

      this.$axios
        .post(
          process.env.VUE_APP_API_URL +
            "/supplier-payments/fetch-completed-payments",
          {}
        )
        .then((response) => {
          this.completedPayments = response.data;
          this.currentCompletedPayments = this.completedPayments;
          this.gatherSuppliers();
          this.loadingPayments = false;
        });
    },
    createCardElement(container) {
      if (!this.stripe) {
        throw "Invalid Stripe Key/Secret";
      }

      var card = this.stripe.elements().create("card", {
        // hideIcon: true,
        // hidePostalCode: true,
        style: {
          base: {
            "::placeholder": {
              color: "#aab7c4",
            },
            fontFamily:
              'Whitney, Lato, -apple-system, BlinkMacSystemFont,"Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji","Segoe UI Emoji", "Segoe UI Symbol"',
            color: "#495057",
            fontSize: "15px",
          },
        },
      });

      card.mount(container);

      return card;
    },
    gatherSuppliers() {
      //collect all distinct suppliers
      //used to dynamically build the supplier filter list
      this.distinctSuppliers = [];
      for (var i = 0; i < this.completedPayments.length; i++) {
        if (
          this.distinctSuppliers.indexOf(
            this.completedPayments[i].supplier.name
          ) === -1
        ) {
          this.distinctSuppliers.push(this.completedPayments[i].supplier.name);
        }
      }
      for (var i = 0; i < this.outstandingBills.length; i++) {
        if (
          this.distinctSuppliers.indexOf(
            this.outstandingBills[i].supplier.name
          ) === -1
        ) {
          this.distinctSuppliers.push(this.outstandingBills[i].supplier.name);
        }
      }
    },
    filterBySupplier(supplier) {
      if (supplier) {
        if (this.currentSupplierFilter === supplier) {
          this.currentSupplierFilter = null;
        } else {
          this.currentSupplierFilter = supplier;
        }
        this.applyFilters();
      }
    },
    applyFilters() {
      var tempPayments = [];
      this.completedPayments.forEach((item) => {
        if (this.currentSupplierFilter) {
          //filter by supplier name
          if (item.supplier.name === this.currentSupplierFilter) {
            tempPayments.push(item);
          }
        } else {
          // no filters
          tempPayments.push(item);
        }
      });

      this.currentCompletedPayments = tempPayments;

      var tempBills = [];
      this.outstandingBills.forEach((item) => {
        if (this.currentSupplierFilter) {
          //filter by supplier name
          if (item.supplier.name === this.currentSupplierFilter) {
            tempBills.push(item);
          }
        } else {
          // no filters
          tempBills.push(item);
        }
      });
      this.currentOutstandingBills = tempBills;
    },
    showAllSuppliers() {
      this.currentSupplierFilter = null;
      this.applyFilters();
    },
    switchToTab(index) {
      this.viewTab = index;
    },
    toOrders() {
      this.$router.push('/orders');
    }
  },
  computed: {
    selectedBills: function () {
      let selected = [];
      for (var i = 0; i < this.outstandingBills.length; i++) {
        if (this.checkedBills.includes(this.outstandingBills[i].id)) {
          selected.push(this.outstandingBills[i]);
        }
      }

      return selected;
    },
    totalSelectedAmount: function () {
      var total = 0.0;
      for (var i = 0; i < this.selectedBills.length; i++) {
        total += parseFloat(this.selectedBills[i].amountOutstanding);
      }

      return total.toFixed(2);
    },
  },
  watch: {
    // whenever question changes, this function will run
    selectedBill: function (newBill, oldbill) {
      this.billPaymentAmount = parseFloat(newBill.amountOutstanding).toFixed(2);
    },
  },
  mounted() {
    this.fetchOutstandingBills();
    this.fetchCompletedPayments();
  },
  filters: {
    formatDate(date) {
      return moment(date).format("LL");
    },
    formatUppercaseWords(type) {
      return type.replace("_", " ").replace(/\w\S*/g, function (txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
      });
    },
  },
  components: {
    CurrencyInput,
    Loading
  },
};
</script>

<style scoped>
  .close {
    border: 0px;
    background-color: #fff;
    font-size: 2rem;
  }
</style>
